import React, { lazy } from "react";
/** Layouts */
import Dashboard from "./shared/layouts/Dashboard";
import LoginComponent from "./pages/Login";
import WrapComponent from "./shared/layouts/WrapComponent";

const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
const Calendar = lazy(() => import("./pages/Calendar"));
const SocialMedia = lazy(() => import("./pages/SocialMedia"));
const Resources = lazy(() => import("./pages/resources"));
const Institutions = lazy(() => import("./pages/institutions"));

const KOLNetworkMap = lazy(() => import("./pages/KOLNetworkMap"));
const KOLNetworkMapSingle = lazy(() => import("./pages/KOLNetworkMapSingle"));
const KOLProfiles = lazy(() => import("./pages/KOLProfiles"));
const KolDetail = lazy(() => import("./pages/KOLDetail"));
const KmeReports = lazy(() => import("./pages/KMEReports"));
const CongressReports = lazy(() => import("./pages/CongressReports"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const ResetPasswprd = lazy(() => import("./pages/ResetPassword"));
const PowerBiReport = lazy(() => import("./pages/PowerBiReports"));

export interface RouteProps {
  path: string;
  exact?: boolean;
  component: React.FC;
  layout: React.FC<any>;
  title: string;
  layoutStyles?: any;
}

export const routes: RouteProps[] = [
  {
    path: "/Login",
    exact: true,
    component: Home,
    title: `Login`,
    layout: LoginComponent,
  },
  {
    path: "/forgot-password",
    exact: true,
    component: ForgotPassword,
    title: `ForgotPassword`,
    layout: WrapComponent,
  },
  {
    path: "/reset-password/:userId/:token",
    exact: true,
    component: ResetPasswprd,
    title: `ResetPassword`,
    layout: WrapComponent,
  },
  {
    path: "/",
    exact: true,
    component: Home,
    title: `Gattex`,
    layout: Dashboard,
  },
  { path: "/about", component: About, title: `About`, layout: Dashboard },
  {
    path: "/calendar",
    component: Calendar,
    title: `Calendar`,
    layout: Dashboard,
  },
  // {
  //   path: "/social-media",
  //   component: SocialMedia,
  //   title: `Social Media`,
  //   layout: Dashboard,
  // },
  {
    path: "/institutions",
    component: Institutions,
    title: `Institutions`,
    layout: Dashboard,
  },
  {
    path: "/resources",
    component: Resources,
    title: `Resources`,
    layout: Dashboard,
  },
  {
    path: "/kol-profiles",
    component: KOLProfiles,
    title: `KOLProfiles`,
    layout: Dashboard,
  },
  {
    path: "/kol/:profileId",
    component: KolDetail,
    title: `KOLSearch`,
    layout: Dashboard,
  },
  {
    path: "/resources",
    component: Resources,
    title: `Resources`,
    layout: Dashboard,
  },
  {
    path: "/kol-network-map/:kolId",
    component: KOLNetworkMapSingle,
    title: `KOL Network Map`,
    layout: Dashboard,
  },
  {
    path: "/kol-network-map",
    exact: true,
    component: KOLNetworkMap,
    title: `KOL Network Map`,
    layout: Dashboard,
  },
  { path: "/congress-reports", component: CongressReports, title: `Congress Reports`, layout: Dashboard },
  { path: "/kol-reports", component: process.env.REACT_APP_ENV === "QA" ? PowerBiReport : KmeReports, title: `KME Reports`, layout: Dashboard },
  { path: "/kol", component: KolDetail, title: `KOLSearch`, layout: Dashboard },
];
